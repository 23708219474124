<template>
  <v-app class="pt-15">
    <customize-header homeRouter="/AI/AIHome"
                      homeTitle="AI智能"
                      :links="links"></customize-header>
    <router-view></router-view>
    <customize-suspendBtn></customize-suspendBtn>
    <customize-footer></customize-footer>
  </v-app>
</template>

<script>
export default {
  name: 'AI',
  data: () => ({
    links: [
      {
        title: "首页",
        router: "/AI/AIHome",
        children: []
      },
      {
        title: "解决方案",
        router: "/AI/Solution",
        children: []
      },
      {
        title: "AI学科",
        router: "/AI/Case",
        children: []
      }
    ],
  }),
};
</script>
<style lang="scss" scoped>

</style>